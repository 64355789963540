let abortController: AbortController | null = null

export const useAbortRequest = () => {
    if (abortController) {
        abortController.abort()
    }

    abortController = new AbortController()
    const signal = abortController.signal

    return { signal }
}
