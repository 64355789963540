<script setup lang="ts">
    import { type Betriebspruefung } from '@/js/model'
    import { formatDateTimeNoSeconds } from '@/utils'
    import { computed } from 'vue'
    import dayjs from 'dayjs'

    const props = defineProps<{
        betriebspruefung: Betriebspruefung
    }>()

    const btnColor = computed(() => {
        const now = dayjs()
        const terminAm = dayjs(props.betriebspruefung.terminAm)
        if (terminAm < now.startOf('month')) {
            return 'red'
        }
        const endOfMonth = now.endOf('month')
        if (terminAm >= now.startOf('month') && terminAm < endOfMonth) {
            return 'yellow'
        }
        if (terminAm >= endOfMonth && terminAm < now.add(3, 'month').endOf('month')) {
            return 'green'
        }

        return ''
    })
</script>

<template>
    <v-btn
        v-if="!betriebspruefung.isAbgeschlossen"
        append-icon="mdi-pencil"
        variant="elevated"
        :color="btnColor"
        data-test="termin-am-cell"
        class="mt-1"
        size="small"
        >{{ formatDateTimeNoSeconds(betriebspruefung.terminAm) }}</v-btn
    >
</template>

<style scoped></style>
