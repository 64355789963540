import { type Dokument } from '@/js/model'
import { useFetch } from '@/utils'

export const useDokumentService = () => {
    const getDokumentById = (id: string) => {
        return getDokumentByIri(`/api/dokumente/${id}`)
    }

    const getDokumentByIri = (iri: Dokument['@id']) => {
        return useFetch({ baseURL: iri }).get<Dokument>({})
    }

    return {
        getDokumentById,
        getDokumentByIri
    }
}
