import './css/vera-app.css'

import './plugins/dayjs.ts'
import { createApp } from 'vue'
import { createPinia } from 'pinia'
import router from './router'

// @ts-ignore
import VueImageZoomer from 'vue-image-zoomer'
import 'vue-image-zoomer/dist/style.css'

// Vuetify
import 'vuetify/styles'
import '@mdi/font/css/materialdesignicons.css'
import { createVuetify } from 'vuetify'

import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'

import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { de } from 'vuetify/locale'

import App from './App.vue'

const pinia = createPinia()
const veraApp = createApp(App)
const vuetify = createVuetify({
    // Check https://pictogrammers.com/library/mdi/ for possible icons & their names
    icons: {
        defaultSet: 'mdi'
    },
    locale: {
        locale: 'de',
        messages: { de }
    },
    components,
    directives
})

veraApp
    .component('VueDatePicker', VueDatePicker)
    .use(router)
    .use(pinia)
    .use(vuetify)
    .use(VueImageZoomer)
    .mount('#app')
