<script setup lang="ts">
    import { computed, ref, watch } from 'vue'
    const props = defineProps<{
        file: File
    }>()

    const preview = ref<string | null>(null)

    const isPdf = computed(() => {
        return props.file.type === 'application/pdf'
    })

    watch(
        () => props.file,
        (newFile) => {
            if (newFile) {
                const reader = new FileReader()
                reader.readAsDataURL(newFile)
                reader.onload = () => {
                    preview.value = reader.result as string
                }
            } else {
                preview.value = null
            }
        },
        { immediate: true }
    )
</script>

<template>
    <v-container v-if="preview">
        <div v-if="isPdf" style="width: 300px" class="mt-4">
            <iframe :src="preview" width="300" height="100%"></iframe>
        </div>
        <v-img v-else :src="preview" max-width="300" min-width="200" class="mt-4"></v-img>
        <div class="overflow-x-auto text-no-wrap text-caption" style="max-width: 300px">
            {{ file.name }}
        </div>
    </v-container>
</template>
<style scoped></style>
