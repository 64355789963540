import { useFetchItems } from '@/composables'
import type { UserResponse, User } from '@/js/model'
import { useFetch, type FetchParams } from '@/utils'

export const useUserService = () => {
    const fetch = useFetch({ baseURL: '/api/users' })

    const getAllUsers = (fetchParams: FetchParams) => {
        const fetch = useFetchItems({ baseURL: '/api/users' })

        return fetch.get<UserResponse>({ fetchParams })
    }

    const getUserById = (id: User['userId']) => {
        return fetch.get<User>({ url: id })
    }

    const getUserByIri = (iri?: User['@id'] | null) => {
        return useFetch({ baseURL: iri ?? '' }).get<User>({})
    }

    return {
        getAllUsers,
        getUserById,
        getUserByIri
    }
}
