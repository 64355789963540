import { useFetchItems } from '@/composables'
import type {
    Betriebspruefung,
    BetriebspruefungenResponse,
    ChangeBemerkungOfBetriebspruefungRequest,
    ChangeErgebnisOfBetriebspruefungRequest,
    EntferneBetriebspruefungsdokumentRequest,
    FuegeBetriebspruefungsdokumentHinzuRequest,
    LegeBetriebspruefungsterminFestRequest,
    SchliesseBetriebspruefungAbRequest
} from '@/js/model'
import { type FetchParams, useFetch } from '@/utils'

export const useBetriebspruefungService = () => {
    const getAllBetriebspruefungen = async (fetchParams: FetchParams = {}) => {
        const fetchItems = useFetchItems({ baseURL: '/api/betriebspruefungen' })

        return await fetchItems.get<BetriebspruefungenResponse>({ fetchParams })
    }

    const exportBetriebspruefungen = async (
        fetchParams: FetchParams = {},
        options?: { signal: AbortSignal }
    ) => {
        return await useFetch({
            baseURL: '/api/betriebspruefungen/export'
        }).get<any>({ fetchParams, signal: options?.signal, isCsv: true })
    }

    const getBetriebspruefungById = (id: string) => {
        return getBetriebspruefungByIri(`/api/betriebspruefungen/${id}`)
    }

    const getBetriebspruefungByIri = (iri: Betriebspruefung['@id']) => {
        return useFetch({ baseURL: iri ?? '' }).get<Betriebspruefung>({})
    }

    const legeBetriebspruefungsterminFest = async (
        request: LegeBetriebspruefungsterminFestRequest
    ) => {
        return await useFetch({
            baseURL: '/api/betriebspruefungen/lege-betriebspruefungstermin-fest'
        }).post<Betriebspruefung>({ body: request })
    }

    const changeBemerkungOfBetriebspruefung = async (
        request: ChangeBemerkungOfBetriebspruefungRequest
    ) => {
        return await useFetch({
            baseURL: '/api/betriebspruefungen/change-bemerkung'
        }).post<Betriebspruefung>({ body: request })
    }

    const schliesseBetriebspruefungAb = async (request: SchliesseBetriebspruefungAbRequest) => {
        return await useFetch({
            baseURL: '/api/betriebspruefungen/schliesse-betriebspruefung-ab'
        }).post<Betriebspruefung>({ body: request })
    }

    const changePruefungsergebnis = async (request: ChangeErgebnisOfBetriebspruefungRequest) => {
        return await useFetch({
            baseURL: '/api/betriebspruefungen/change-ergebnis'
        }).post<Betriebspruefung>({ body: request })
    }

    const fuegeBetriebspruefungsdokumentHinzu = async (
        request: FuegeBetriebspruefungsdokumentHinzuRequest
    ) => {
        return await useFetch({
            baseURL: '/api/betriebspruefungen/fuege-betriebspruefungsdokument-hinzu'
        }).post<Betriebspruefung>({ body: request })
    }

    const entferneBetriebspruefungsdokument = async (
        request: EntferneBetriebspruefungsdokumentRequest
    ) => {
        return await useFetch({
            baseURL: '/api/betriebspruefungen/entferne-betriebspruefungsdokument'
        }).post<Betriebspruefung>({ body: request })
    }

    return {
        exportBetriebspruefungen,
        getAllBetriebspruefungen,
        getBetriebspruefungById,
        legeBetriebspruefungsterminFest,
        changeBemerkungOfBetriebspruefung,
        schliesseBetriebspruefungAb,
        changePruefungsergebnis,
        fuegeBetriebspruefungsdokumentHinzu,
        entferneBetriebspruefungsdokument
    }
}
