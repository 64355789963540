export const useForceFileDownload = (response: ArrayBuffer | Blob, title: string): void => {
    const blob = response instanceof Blob ? response : new Blob([response])
    const url: string = window.URL.createObjectURL(blob)
    const link: HTMLAnchorElement = document.createElement('a')
    link.href = url
    link.setAttribute('download', title)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    window.URL.revokeObjectURL(url)
}
