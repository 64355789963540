import { useFetchItems } from '@/composables'
import type { FachbetriebeResponse, Fachbetrieb } from '@/js/model'
import { type FetchParams, useFetch } from '@/utils'

export const useFachbetriebService = () => {
    const fetch = useFetch({ baseURL: '/api/fachbetriebe' })

    const getFachbetriebById = async (id: string): Promise<Fachbetrieb> => {
        return await fetch.get<Fachbetrieb>({
            url: `/api/fachbetriebe/${id}`
        })
    }

    const getAllFachbetriebe = (fetchParams: FetchParams = {}): Promise<FachbetriebeResponse> => {
        const fetch = useFetchItems({ baseURL: '/api/fachbetriebe' })

        return fetch.get<FachbetriebeResponse>({ fetchParams })
    }

    const getAnzahlFachbetriebeByFachprueferId = async (fachprueferId: string) => {
        const fetchParams: FetchParams = {
            itemsPerPage: 0,
            fachprueferId
        }
        return await fetch.get<FachbetriebeResponse>({ fetchParams })
    }

    return {
        getFachbetriebById,
        getAllFachbetriebe,
        getAnzahlFachbetriebeByFachprueferId
    }
}
