import type { ImportedFilesResponse } from '@/js/model'
import { type FetchParams, useFetch } from '@/utils'

export const useImportedFileService = () => {
    const fetch = useFetch({ baseURL: '/api/imported-files' })

    const getAllImportedFiles = (
        fetchParams: FetchParams = {},
        options?: { signal: AbortSignal }
    ) => {
        return fetch.get<ImportedFilesResponse>({ fetchParams, signal: options?.signal })
    }

    return {
        getAllImportedFiles
    }
}
