<script lang="ts" setup>
    import { useAlertStore } from '@/stores'
    import { Navigation } from '@/components'

    const alertStore = useAlertStore()
</script>

<template>
    <v-app>
        <v-container class="main-container">
            <Navigation />

            <v-main>
                <v-container fluid>
                    <RouterView />
                </v-container>
            </v-main>
        </v-container>

        <v-snackbar
            v-model="alertStore.showAlert"
            multi-line
            timeout="15000"
            transition="false"
            :color="alertStore.alert?.type ?? undefined"
        >
            {{ alertStore.alert?.message }}
            <template v-slot:actions>
                <v-btn variant="text" @click="alertStore.clear()">
                    <v-icon icon="mdi-close"></v-icon>
                </v-btn>
            </template>
        </v-snackbar>
    </v-app>
</template>

<style scoped>
    .main-container {
        max-width: 2400px;
        margin: 0 auto;
        padding: 16px;
        background-color: #f4f4f9;
        min-height: 100dvh;
    }
</style>
