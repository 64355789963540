<script setup lang="ts">
    import { type Betriebspruefung, type Schulung, type Schulungen } from '@/js/model'
    import { computed } from 'vue'
    import dayjs from 'dayjs'
    import { formatDate } from '@/utils'

    const props = defineProps<{
        betriebspruefung: Betriebspruefung
        schulungen: Schulungen
    }>()

    enum SchulungsStatus {
        Teilgenommen,
        Bestanden,
        NichtBestanden,
        KeineSchulung
    }

    const schulungsStatus = computed<SchulungsStatus>(() => {
        const terminAm = dayjs(props.betriebspruefung.terminAm)
        const twoYearsAgo = terminAm.subtract(2, 'years')

        const latestSchulungen = props.schulungen.schulungen.filter((schulung: Schulung) => {
            const schulungAt = dayjs(schulung.schulungAt)
            return schulungAt.isAfter(twoYearsAgo) && schulungAt.isBefore(terminAm)
        })

        if (latestSchulungen.length === 0) {
            return SchulungsStatus.KeineSchulung
        }

        if (
            latestSchulungen.some((schulung: Schulung) => {
                return schulung.isWiederholungsschulung
            })
        ) {
            return SchulungsStatus.Teilgenommen
        }

        if (
            latestSchulungen.some((schulung: Schulung) => {
                return schulung.isBestanden
            })
        ) {
            return SchulungsStatus.Bestanden
        }

        return SchulungsStatus.NichtBestanden
    })

    const letzteErfolgreicheSchulung = computed<Schulung | undefined>(() => {
        return props.schulungen.schulungen.find((schulung: Schulung) => {
            return schulung.isBestanden || schulung.isWiederholungsschulung
        })
    })
</script>

<template>
    <span class="text-no-wrap chip-spacing">
        <v-chip
            v-if="schulungsStatus === SchulungsStatus.KeineSchulung"
            color="error"
            prepend-icon="mdi-close"
            >Schulung fehlt</v-chip
        >
        <v-chip
            v-else-if="schulungsStatus === SchulungsStatus.NichtBestanden"
            color="error"
            prepend-icon="mdi-close"
            >FBS nicht bestanden</v-chip
        >
        <v-chip v-else color="success" prepend-icon="mdi-check-circle-outline">
            <span v-if="schulungsStatus === SchulungsStatus.Teilgenommen"> WS teilgenommen </span>
            <span v-else>FBS bestanden</span>
            &nbsp;am&nbsp;
            <span v-if="letzteErfolgreicheSchulung">
                {{ formatDate(letzteErfolgreicheSchulung.schulungAt) }}
            </span>
        </v-chip>
    </span>
</template>

<style scoped>
    .chip-spacing {
        margin-top: 1px;
        margin-bottom: 1px;
        display: inline-block;
    }
</style>
