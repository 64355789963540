import { useFetch, type FetchParams } from '@/utils'
import { useAbortRequest } from '@/composables'
import { useDebounceFn } from '@vueuse/core'

export const useFetchItems = ({ baseURL }: { baseURL: string }) => {
    const fetch = useFetch({ baseURL })
    const { signal } = useAbortRequest()

    return {
        get: async <T>({ fetchParams }: { fetchParams: FetchParams }) =>
            await useDebounceFn(async () => {
                return await fetch.get<T>({ fetchParams, signal })
            }, 300)()
    }
}
