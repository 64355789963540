import {
    type BetriebsbeauftragterFilterSettings,
    type BetriebspruefungFilterSettings,
    type FachbetriebFilterSettings
} from '@/composables/settings'

export const useSettings = () => {
    const loadBetriebspruefungFilter = (): BetriebspruefungFilterSettings | null => {
        const betriebspruefungFilterSettings = localStorage.getItem(
            'settings.betriebspruefung.filter'
        )
        if (betriebspruefungFilterSettings !== null) {
            return JSON.parse(betriebspruefungFilterSettings)
        }
        return null
    }

    const storeBetriebspruefungFilter = (
        betriebspruefungFilter: BetriebspruefungFilterSettings
    ) => {
        localStorage.setItem(
            'settings.betriebspruefung.filter',
            JSON.stringify(betriebspruefungFilter)
        )
    }

    const loadFachbetriebFilter = (): FachbetriebFilterSettings | null => {
        const fachbetriebFilterSettings = localStorage.getItem('settings.fachbetrieb.filter')
        if (fachbetriebFilterSettings !== null) {
            return JSON.parse(fachbetriebFilterSettings)
        }
        return null
    }

    const storeFachbetriebFilter = (fachbetriebFilter: FachbetriebFilterSettings) => {
        localStorage.setItem('settings.fachbetrieb.filter', JSON.stringify(fachbetriebFilter))
    }

    const loadBetriebsbeauftragterFilter = (): BetriebsbeauftragterFilterSettings | null => {
        const betriebsbeauftragterFilterSettings = localStorage.getItem(
            'settings.betriebsbeauftragter.filter'
        )
        if (betriebsbeauftragterFilterSettings !== null) {
            return JSON.parse(betriebsbeauftragterFilterSettings)
        }
        return null
    }

    const storeBetriebsbeauftragterFilter = (
        betriebsbeauftragterFilter: BetriebsbeauftragterFilterSettings
    ) => {
        localStorage.setItem(
            'settings.betriebsbeauftragter.filter',
            JSON.stringify(betriebsbeauftragterFilter)
        )
    }

    return {
        loadBetriebspruefungFilter,
        storeBetriebspruefungFilter,
        loadFachbetriebFilter,
        storeFachbetriebFilter,
        loadBetriebsbeauftragterFilter,
        storeBetriebsbeauftragterFilter
    }
}
