import { defineStore } from 'pinia'
import { ref } from 'vue'
import type { Zertifizierung } from '@/js/model'
import { useZertifizierungService } from '@/services'

export const useZertifizierungStore = defineStore('zertifizierung', () => {
    const zertifizierungen = ref<Zertifizierung[]>([])
    const service = useZertifizierungService()

    const getZertifizierungById = (id: string): Zertifizierung | null => {
        const zertifizierung = zertifizierungen.value.find((item) => item.zertifizierungId === id)
        if (zertifizierung) {
            return zertifizierung
        } else {
            return null
        }
    }

    const fetchZertifizierungById = async (id: string): Promise<void> => {
        addOrReplaceZertifizierung(await service.getZertifizierungById(id))
    }

    const addOrReplaceZertifizierung = (zertifizierung: Zertifizierung): void => {
        const index = zertifizierungen.value.findIndex(
            (item) => item.zertifizierungId === zertifizierung.zertifizierungId
        )

        if (index !== -1) {
            zertifizierungen.value[index] = zertifizierung
        } else {
            zertifizierungen.value.push(zertifizierung)
        }
    }

    return {
        zertifizierungen,
        addOrReplaceZertifizierung,
        getZertifizierungById,
        fetchZertifizierungById
    }
})
