import { defineStore } from 'pinia'
import { ref } from 'vue'
import type { User } from '@/js/model'
import { useUserService } from '@/services'

export const useUserStore = defineStore('user', () => {
    const users = ref<User[]>([])
    const service = useUserService()

    const getUserById = (id: string): User | null => {
        const user = users.value.find((item) => item.userId === id)
        if (user) {
            return user
        } else {
            return null
        }
    }

    const fetchUserById = async (id: string): Promise<void> => {
        addOrReplaceUser(await service.getUserById(id))
    }

    const addOrReplaceUser = (user: User): void => {
        const index = users.value.findIndex((item) => item.userId === user.userId)

        if (index !== -1) {
            users.value[index] = user
        } else {
            users.value.push(user)
        }
    }

    return {
        users,
        getUserById,
        fetchUserById
    }
})
