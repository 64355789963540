<script setup lang="ts">
    import { computed, onMounted, ref } from 'vue'
    import { useDokumentStore, useUserStore } from '@/stores'
    import { type Dokument, type User } from '@/js/model'
    import { formatDate } from '@/utils'

    const props = withDefaults(
        defineProps<{
            dokumentId: Dokument['dokumentId']
            isWithDelete?: boolean
        }>(),
        {
            isWithDelete: true
        }
    )

    const userStore = useUserStore()
    const dokumentStore = useDokumentStore()
    const overlay = ref<boolean>(false)
    const deleteDialog = ref<boolean>(false)
    const isDeleteLoading = ref<boolean>(false)

    onMounted(async () => {
        await dokumentStore.fetchDokumentById(props.dokumentId)
        if (!user.value && dokument.value?.hinzugefuegtBy?.userId) {
            userStore.fetchUserById(dokument.value.hinzugefuegtBy.userId)
        }
    })

    const user = computed<User | null>(() => {
        if (!dokument.value || !dokument.value.hinzugefuegtBy.userId) {
            return null
        }
        return userStore.getUserById(dokument.value.hinzugefuegtBy.userId)
    })
    const dokument = computed<Dokument | null>(() => {
        return dokumentStore.getDokumentById(props.dokumentId) ?? null
    })
    const isImage = computed<boolean>(() => {
        if (!dokument.value) {
            return false
        }
        return ['jpg', 'jpeg', 'png', 'bmp'].includes(
            dokument.value.dokument.extension.toLowerCase()
        )
    })
    const formatFileSize = (bytes: number, decimals = 2): string => {
        if (!+bytes) return '0 Bytes'

        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']

        const i = Math.floor(Math.log(bytes) / Math.log(k))

        return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))
            .toString()
            .replace('.', ',')} ${sizes[i]}`
    }
    const isPdf = computed<boolean>(() => {
        if (!dokument.value) {
            return false
        }
        return 'pdf' === dokument.value.dokument.extension.toLowerCase()
    })
    const handleDelete = async () => {
        if (!dokument.value) {
            return
        }
        isDeleteLoading.value = true

        await dokumentStore.entferneBetriebspruefungsdokument(dokument.value)
        deleteDialog.value = false
        isDeleteLoading.value = false
    }
</script>

<template>
    <v-card v-if="dokument" :loading="!dokument">
        <v-dialog v-model="deleteDialog" width="auto">
            <v-card>
                <v-card-title>Wirklich löschen?</v-card-title>
                <v-card-text>
                    <v-alert type="info">
                        Achtung, die Datei wird vom System gelöscht.
                        <br />
                        Ein Wiederherstellen ist nicht möglich.
                    </v-alert>
                </v-card-text>
                <v-card-actions>
                    <v-btn
                        variant="elevated"
                        :href="dokument.src"
                        size="small"
                        @click="deleteDialog = false"
                        >Abbrechen</v-btn
                    >
                    <v-spacer />
                    <v-btn
                        variant="elevated"
                        prepend-icon="mdi-trash-can-outline"
                        target="_blank"
                        size="small"
                        @click="handleDelete"
                        >Jetzt Löschen</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-img
            v-if="isImage"
            :src="dokument.src"
            max-width="250"
            min-width="250"
            class="mt-4 ml-4 cursor-pointer"
            :title="dokument.dokument.filename"
            @click="overlay = !overlay"
        />
        <v-overlay
            v-if="isImage"
            v-model="overlay"
            content-class="d-flex align-center justify-center w-100 h-100"
            max-height="95%"
        >
            <v-card max-width="95%" max-height="95%">
                <v-card-title>{{ dokument.dokument.filename }}</v-card-title>
                <v-card-text class="d-flex justify-center pt-4 border">
                    <vue-image-zoomer
                        hover-message="Drüberfahren um zu zoomen"
                        :title="dokument.dokument.filename"
                        :regular="dokument.src"
                        img-class="border w-100"
                    />
                </v-card-text>
                <v-card-actions>
                    <v-btn variant="tonal" color="primary" block @click="overlay = false"
                        >Schließen</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-overlay>
        <div v-else-if="isPdf" style="width: 250px">
            <iframe :src="dokument.src" width="250px" height="100%"></iframe>
        </div>
        <v-card-item>
            <v-card-title>{{ (dokument.dokument.dokumentTyp as any)?.typ }}</v-card-title>

            <v-card-subtitle :title="dokument.dokument.filename">
                <v-icon v-if="isPdf" :icon="`mdi-file-pdf-box`"></v-icon>
                {{ dokument.dokument.filename }}
            </v-card-subtitle>
            <v-card-subtitle>
                Hinzugefügt am: {{ formatDate(dokument.dokument.dokumentDate) }}
            </v-card-subtitle>
            <v-card-subtitle v-if="user">
                Hinzugefügt von: {{ user.vorname }} {{ user.nachname }}
            </v-card-subtitle>
            <v-card-subtitle>
                Größe: {{ formatFileSize(dokument.dokument.dokumentSize) }}
            </v-card-subtitle>
            <v-card-actions>
                <v-btn
                    variant="elevated"
                    prepend-icon="mdi-download"
                    target="_blank"
                    :href="dokument.src"
                    :disabled="isDeleteLoading"
                    size="small"
                    >Download</v-btn
                >
                <v-spacer />
                <v-btn
                    v-if="isWithDelete"
                    variant="elevated"
                    prepend-icon="mdi-trash-can-outline"
                    target="_blank"
                    size="small"
                    :disabled="isDeleteLoading"
                    @click="deleteDialog = true"
                    >Löschen</v-btn
                >
            </v-card-actions>
        </v-card-item>
    </v-card>
</template>
<style scoped></style>
